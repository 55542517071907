<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <p>Profil bearbeiten</p>
      </h1>
    </header>

    <div class="content-wrap">
      <h3>Ihre Daten</h3>

      <div class="row row-gutter-20" style="margin-bottom:10px">
        <div class="col-12">
          <div class="form-wrap">
            <label for="first_name" class="form-label">Vorname</label>
            <input v-model="user.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap">
            <label for="last_name" class="form-label">Nachname</label>
            <input v-model="user.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
          </div>
        </div>
      </div>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="form-wrap">
            <label for="phone_number" class="form-label">Telefon</label>
            <input v-model="user.phone_number" class="form-input input-grey" type="text" name="phone_number" id="phone_number">
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap">
            <label for="email" class="form-label">E-Mail</label>
            <input v-model="user.email" class="form-input input-grey" type="text" name="email" id="email">
          </div>
        </div>
      </div>

      <p style="margin-bottom:0;text-align:right">
        <a @click="update_user" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk" class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"></path>
          </svg>
          <span>Daten speichern ›</span>
        </a>
      </p>

    </div>

    <div class="content-wrap">
      <h3>Passwort ändern</h3>

      <div class="row row-gutter-20" style="margin-bottom:10px">
        <div class="col-12">
          <div class="form-wrap">
            <label for="first_name" class="form-label">Neues Passwort</label>
            <input v-model="password" class="form-input input-grey" type="password" name="first_name" id="first_name">
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap">
            <label for="last_name" class="form-label">Neues Passwort wiederholen</label>
            <input v-model="password_confirmation" class="form-input input-grey" type="password" name="last_name" id="last_name">
          </div>
        </div>
      </div>

      <p style="margin-bottom:0;text-align:right">
        <a @click="update_password" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk" class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"></path>
          </svg>
          <span>Daten speichern ›</span>
        </a>
      </p>

    </div>



  </div>
</template>

<script>
export default {
  name: 'admin_user',
  data () {
    return {
      user: {},
      password: null,
      password_confirmation: null
    }
  },
  methods: {
    async get_data() {
      this.password = null;
      this.password_confirmation = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/self', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
      })
    },
    update_user() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user.id, this.user, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    update_password() {
      if (!this.password) {
        this.$notify({
          title: "Bitte Passwort ausfüllen.",
          type: "error"
        });
      } else if (this.user.password == this.user.password_confirmation) {
        this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user.id, {
          password: this.password,
          password_confirmation: this.password_confirmation
        }, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      } else {
        this.$notify({
          title: "Passwort stimmt nicht überein.",
          type: "error"
        });
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
